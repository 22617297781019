@charset 'utf-8';
@import 'settings';

html {
	.frame-tt-address {
		.vcard {
			h4 {
				text-transform: none;
				font-family: $body-font-family;
				font-size: 1.125rem;
			}

			address {
				font-family: $body-font-family;
				font-size: 1.125rem;
				font-style: normal;
			}
		}
	}

	[class^="row element"] {
		//padding: 1rem 0;
		z-index: -10;

		.tx-ttaddress {
			.large-4,
			.medium-4,
			.small-4 {
				max-width: 31.25%;
				flex: 0 0 31.25%;
				margin: 0 1rem;
			}

			.large-6,
			.medium-6,
			.small-6 {
				max-width: 48.5%;
				flex: 0 0 48.5%;
				margin: 0 1rem;
			}

			.figure {
				width: 10.5rem;
				height: 10.5rem;
				overflow: hidden;
			}

			.rounded {
				border-radius: 50%;
			}

			.square {
			}

			.vcard {
				margin: 0;
				border-bottom: 1px solid $lightgray;

				label {
					font-size: 1.125rem;
					display: inline-block;
					font-family: $body-font-familyBold;
					//min-width: 5rem;
				}

				a {
					font-size: 1.125rem;
					display: inline-block;
					font-family: $body-font-familyBold;
					text-transform: none;
				}

				a::before {
					display: none;
				}

				.email + .signafter {
					margin-top: .75rem;
				}
			}

			.tt_address_list {
				padding: 0 .9375rem;
			}

			.tt_address_list.workers,
            .tt_address_list.voluntary {
				margin: 1rem -.9375rem;
			}

			.tt_address_list.worker {
				.large-8 {
					min-height: 13.75rem;
				}
			}

			.tt_address_list.worker,
			.tt_address_list.workers,
            .tt_address_list.voluntary {
				.worker.vcard {
					border: none;
					padding: 2rem 1.75rem 1.75rem;
					margin: .5rem;

					.vcard {
						border: none;
					}

					.position {
						height: 4rem;
					}

					h5 {
						text-decoration: none;
					}

					a::after {
						content: "";
						display: none;
					}

					.addressImage {
						margin-right: 0;
						margin-left: 0;
						padding: 0;

						a {
							margin-left: .9375rem;
							width: 9rem;
							height: 9rem;
							overflow: hidden;
						}

						//padding-left: 0;
						a::after {
							display: none;
						}
					}

					label {
						margin: 0;
						max-width: 4.75rem;
					}

					a {
						color: $darkgray;
					}

					a:focus,
					a:hover {
						color: $darkblue;
					}

					a.right::after {
						float: right;
						color: $darkblue;
					}

					a span {
						width: auto;
						max-width: none;
					}

					a.signafter {
						span {
							width: 4.75rem;
							display: inline-block;
						}
					}

					a.signafter::after {
						content: "›››";
						display: inline-block;
						padding: 0 1%;
						color: $darkblue;
						//float: right;
					}
				}
			}

			.tt_address_list.shortContact {
				margin: 1rem 0;
			}

			.tt_address_list {
				.selection {
					margin-bottom: 1rem;

					.selectionletter {
						display: grid;
						grid-template-columns: 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5% 3.5%;

						a {
							text-transform: uppercase;
							padding: .5rem 0;
						}

						a.is-active,
						a:focus,
						a:hover {
							color: $darkgray;
						}

						.phone a::after {
							display: none;
							content: "";
						}

					}

					.countrySelect {
						margin-bottom: 0;
						max-width: 16%;
					}
				}

				.selection + .row {
					display: block;
				}

				.worker:nth-child(2n) {
					//  margin-right: 0;
				}

				.worker:nth-child(2n+1) {
					//margin-left: 0;
				}
			}

			.tt_address_list.list {
				.vcardlist {
					display: grid;
					grid-template-columns: 50% 50%;
					padding: 0;
				}

				.column:last-child:not(:first-child),
				.columns:last-child:not(:first-child) {
					float: left;
				}

				.vcard:nth-child(1),
				.vcard:nth-child(2) {
					border-top: 1px solid $lightgray !important;
				}

				.vcard:nth-child(2n+1) {
					grid-column: 1;
					padding: 2.5rem 1.5rem 1rem;
					border-right: 1px solid $lightgray;
					max-width: 100%;
					width: 100%;
				}

				.vcard:nth-child(2n) {
					grid-column: 2;
					padding: 2.5rem 1.5rem 1rem;
					max-width: 100%;
					width: 100%;
				}

				.vcard {
					flex: unset;

					a.read-more {
						display: block;
					}

					a span {
						width: auto;
					}
				}
			}

			.tt_address_list.contactList {
				margin-bottom: 0;

				header {
					width: 100%;
				}

				.shortcontact {
					padding: 1rem 0 .25rem;

					a::before {
						border: 2px solid $darkgray;
						border-radius: 100%;
						width: 15px;
						height: 15px;
						content: " ";
						display: inline-block;
						margin-right: 1.125rem;
						margin-top: .5rem;
						vertical-align: top;
					}

					a {
						color: $darkgray;
						text-transform: uppercase;
						font-size: 1.125rem;
						font-family: $body-font-familyBold;
						position: relative;
						display: block;

						.person,
						.position {
							display: inline-block;
							width: 80%;
						}

						.position {
							margin-left: 2.125rem;
						}

					}

					a::after {
						content: "›››";
						color: $darkblue;
						display: inline-block;
						position: absolute;
						top: 0;
						right: 0;
					}

					a::after,
					a:focus,
					a:hover {
						color: $darkblue;
					}
				}
			}

			.tt_address_detail {
				.text-center {
					margin: 0 auto;

					figure {
						margin: 3.75rem auto;
					}
				}

				p {
					a::after {
						content: "";
						display: none;
					}

					a {
						font-family: $body-font-familyBold;
						color: inherit;
					}

					a:focus,
					a:hover {
						color: $darkblue;
					}
				}

				h2.text-center {
					line-height: normal;
				}

				h4.text-center {
					margin-bottom: 2.25rem;
				}

				.specialdays {
					font-size: 1.625rem;
				}

				.list-contactinformation {
					.row::before {
						content: "";
						display: none;
					}

					label {
						color: $darkgray;
						font-size: 1.125rem;
					}

					a {
						text-transform: none;

						span {
							max-width: none;
							width: auto;
						}
					}

					a::before {
						display: none;
						content: "";
					}

					a::after {
						color: $darkblue;
					}
				}
			}

			.mitarbeiterContact {
				border-top: 1px solid $lightgray;
			}

			.contact {
				padding: 0;

				li {
					margin: 0 0 1.725rem;

					h5 {
						margin-bottom: 0;
					}

					.rounded::after {
						display: none;
						content: "";
					}

					h6.noborder {
						border: none;
					}

					a h6.noborder::after {
						content: "›››";
						float: right;
						color: $darkblue;
					}

					h6:first-child::before {
						border: 2px solid #3d3d3f;
						border-radius: 100%;
						width: 15px;
						height: 15px;
						content: " ";
						display: inline-block;
						margin-right: 1rem;
					}

					.position {
						margin-left: 2.125rem;
					}

					a::before {
						display: none;
					}
				}

				li:last-child {
					margin-bottom: 0;
				}
			}

			ul {
				margin: 0;

				li {
					list-style: none;
					margin: 0;
				}
			}
		}

		.row.element2cols .tx-ttaddress {
			.contact li h6.noborder {
				margin-top: 0;
			}
		}

		.tx-ext-extendttaddress {
			.missionarsList {
				padding: 0 .9375rem;

				#sic_address_search_form {
					margin: 1.25rem auto 4.5rem;
					padding-bottom: 2rem;
					border-bottom: 1px solid $lightgray;
					width: 100%;
				}

				a,
				span {
					color: $darkgray;
				}

				a:focus,
				a:hover {
					color: $darkblue;
				}

				.atoz {
					border-bottom: 1px solid $lightgray;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					position: relative;
					margin: 1.25rem auto 4.5rem;
					padding-bottom: 2rem;
					width: 100%;

					.columns {
						padding: 0;

						a,
						span {
							display: inline-block;
							flex: 1 0 auto;
							font-family: $body-font-familyBold;
							font-size: 1.5rem;
							line-height: normal;
							padding-top: .25rem;
							padding-right: .35rem;
						}

						& ::after {
							content: "";
							border-right: 2px solid $darkgray;
							padding-right: .5rem;
						}

						a:last-child::after,
						span:last-child::after {
							border-right: none;
						}

						.activelinkcolor {
							color: $darkblue;
						}

						.deadlinkcolor {
							color: $lightgray;
						}
					}

				}

				.list {
					display: flex;
					flex-wrap: wrap;
					padding: 0;

					.item {

						.row {
							width: 100%;
						}
					}

					.item.large-6 {
						flex: 48.5% 0 0;
					}
				}

				.pulldown {
					.category {
						margin: 0;
						padding: 0;

						select {
							margin-bottom: 0;
						}
					}
				}

				.vcard {
					margin: .5rem;

					p a::after {
						content: "";
						display: none;
					}

					.addressImage {
						margin: 0;

						[class*="-3"] {
							flex: auto;
							margin: 0;

							figure {
								margin: 0;

								img {
									width: 100%;
								}
							}
						}
					}

					a {
						font-size: 1.125rem;
						font-family: $body-font-familyBold;
					}

					a.signafter::after {
						content: "›››";
						color: $darkblue;
					}

					.text-center {
						margin: 0 auto;

						figure {
							margin: 3.75rem auto;
						}
					}

					.specialdays {
						margin-bottom: 2rem;
					}
				}

				.vcard:nth-child(2n+1) {
					padding: 2.5rem .5rem 1rem;
				}

				.vcard:nth-child(2n) {
					padding: 2.5rem .5rem 1rem;
				}

				.dropdown-menu {
					position: absolute;
					right: 0;

					a.button {
						appearance: none;
						border: 1px solid $lightgray;
						border-radius: 0;
						background-color: $white_transparent;
						background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
						background-origin: content-box;
						background-position: right -1rem center;
						background-repeat: no-repeat;
						background-size: 9px 6px;
						color: $gray;
						display: block !important;
						font-family: inherit;
						font-size: 1rem;
						font-weight: normal;
						line-height: 1.5;
						height: auto !important;
						margin: 0;
						padding: .5rem 1.5rem .5rem .5rem;
						width: 100%;
						text-align: left;
						transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
					}

					.dropdown {
						display: none;
						appearance: none;
						border: 1px solid $lightgray;
						border-radius: 0;
						background-color: $white_transparent;
						background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
						background-origin: content-box;
						background-position: right -1rem center;
						background-repeat: no-repeat;
						background-size: 9px 6px;
						padding: .5rem;

						li {
							::before {
								display: none;
							}

							margin: 0;

							a {
								color: $gray;
								display: block;
								font-family: $body-font-family;
								font-size: 1rem;
								font-weight: normal;
								text-transform: none;
							}

							a:hover, a:focus {
								color: $darkblue;
							}

							& ::after, ::after {
								display: none;
							}
						}

						.active a {
							color: $darkblue;
						}
					}

					.dropdown.is-open,
					a.button.is-open + .dropdown {
						display: block !important;

						li::after, &::after {
							display: none;
						}
					}
				}
			}

			.listview {
				.vcard {
					margin: .5rem;

					p a::after {
						content: "";
						display: none;
					}

					.addressImage {
						margin: 0;

						[class*="-3"] {
							flex: auto;
							margin: 0;
							width: 100%;
							max-width: 100%;

							figure {
								margin: 0;

								img {
									width: 100%;
								}
							}
						}
					}

					a {
						font-size: 1.125rem;
						font-family: $body-font-familyBold;
					}

					.text-center {
						margin: 0 auto;

						figure {
							margin: 3.75rem auto;
						}
					}

					.specialdays {
						margin-bottom: 2rem;
					}
				}

				.vcard:nth-child(2n+1) {
					padding: 2.5rem .5rem 1rem;
				}

				.vcard:nth-child(2n) {
					padding: 2.5rem .5rem 1rem;
				}
			}
		}

		.tt_address_missiontothenorth {
			border-top: 1px solid $lightgray;
			margin: 1rem -.9375rem;
			padding: 2rem 0 0;

			[class*="-12"],
			[class*="-2"] {
				padding: 0;
			}

			.large-2 + [class*="-12"] {
				flex: 0 0 83.33333%;
				max-width: 83.33333%;
			}

			.row {
				margin: 0;
				padding-top: 3.5rem;
				border-top: 1px dotted;
			}
		}

		.roundedImage {
			border-radius: 100%;
			overflow: hidden;
			padding: 0;
			margin: 0 .975rem;
			width: 10rem;
			height: 10rem;
			max-width: 10rem;
		}
	}

}

@media only screen and (min-width: 1024px) {
}

@media only screen and (max-width: 1023px) {
}

/** ------------- https://css-tricks.com/snippets/css/media-queries-for-standard-devices/ ------------- **/
/** gültig für alle Geräte **/
/* -------------------------------------------- */
/* iPhone 2G-4S in portrait & landscape */
@media only screen and (max-device-width: 480px) {
	html {
		body {
			[class^="row element"] {
				.row {
					margin: 0 auto;
				}

				.row.element2cols.col50-50 {
					margin: 0;
				}

				.powermail_fieldset {
					margin: 0 -.75rem;

					.layout2,
					.layout3 {
						padding: 0 .75rem;
					}
				}

				.tx-ttaddress {
					.tt_address_list.shortContact {
						padding: 0;
					}

					.tt_address_list.worker,
					.tt_address_list.workers {
						.worker.vcard {
							width: 100%;
							max-width: 100%;
							flex: unset;

							.addressImage {
								display: block;
								text-align: center;
							}

							.large-8 {
								width: 100%;
								max-width: 100%;
								display: block;

								a {
									padding-left: 0;
								}
							}
						}
					}

					.tt_address_detail {
						.text-center {
							flex: 0 0 100%;
							max-width: 100%;
						}
					}

					.figure {
						width: 10.5rem;
						height: 10.5rem;
						overflow: hidden;
					}

					.rounded {
						border-radius: 50%;
					}

					.square {
					}

					.vcard {
						margin: 0;
						border-bottom: 1px solid $lightgray;

						label {
							font-size: 1.125rem;
							display: inline-block;
							font-family: $body-font-familyBold;
							//min-width: 5rem;
						}

						a {
							font-size: 1.125rem;
							display: inline-block;
							font-family: $body-font-familyBold;
							text-transform: none;
						}

						a::before {
							display: none;
						}
					}

					.contact {
						padding: 0;

						li {
							margin: 0 0 1.725rem;

							h5 {
								margin-bottom: 0;
							}

							.rounded::after {
								display: none;
								content: "";
							}

							h6.noborder {
								border: none;
							}

							a h6.noborder::after {
								content: "›››";
								float: right;
								color: $darkblue;
							}

							h6:first-child::before {
								border: 2px solid #3d3d3f;
								border-radius: 100%;
								width: 15px;
								height: 15px;
								content: " ";
								display: inline-block;
								margin-right: 1rem;
							}

							.position {
								margin-left: 2.125rem;
							}

							a::before {
								display: none;
							}
						}
					}

					ul {
						margin: 0;

						li {
							list-style: none;
							margin: 0;
						}
					}
				}

				.tx-ttaddress {
					.tt_address_list.list {
						.vcardlist {
							grid-template-columns: 100%;
						}

						.vcard {
							a {
								width: 100%;

								span {
									max-width: 100%;
								}
							}

							a.large-10::after {
								display: none;
								content: "";
							}
						}

						.vcard:nth-child(2n),
						.vcard:nth-child(2n+1) {
							grid-column: 1;
							padding: 2.5rem .25rem 1rem;
							border-right: none;
							max-width: 100%;
							width: 100%;
						}
					}
				}

			}

		}
	}
}

/* iPhone 2G-4S in landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
}

/* iPhone 2G-4S in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* iPhone 5 Media Queries */
/* iPhone 5 & 5S in portrait & landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
	html {
		body {
			[class^="row element"] {
				.row {
					margin: 0 auto;
				}

				.row.element2cols.col50-50 {
					margin: 0;
				}

				.powermail_fieldset {
					margin: 0 -.75rem;

					.layout2,
					.layout3 {
						padding: 0 .75rem;
					}
				}

				.tx-ttaddress {
					.tt_address_list.worker,
					.tt_address_list.workers {
						.worker.vcard {
							width: 100%;
							max-width: 100%;
							flex: unset;

							.addressImage {
								display: block;
								text-align: center;
							}

							.large-8 {
								width: 100%;
								max-width: 100%;
								display: block;

								a {
									padding-left: 0;
								}
							}
						}
					}

					.tt_address_detail {
						.text-center {
							flex: 0 0 100%;
							max-width: 100%;
						}
					}

					.figure {
						width: 10.5rem;
						height: 10.5rem;
						overflow: hidden;
					}

					.rounded {
						border-radius: 50%;
					}

					.square {
					}

					.vcard {
						margin: 0;
						border-bottom: 1px solid $lightgray;

						label {
							font-size: 1.125rem;
							display: inline-block;
							font-family: $body-font-familyBold;
							//min-width: 5rem;
						}

						a {
							font-size: 1.125rem;
							display: inline-block;
							font-family: $body-font-familyBold;
							text-transform: none;
						}

						a::before {
							display: none;
						}
					}

					.contact {
						padding: 0;

						li {
							margin: 0 0 1.725rem;

							h5 {
								margin-bottom: 0;
							}

							.rounded::after {
								display: none;
								content: "";
							}

							h6.noborder {
								border: none;
							}

							a h6.noborder::after {
								content: "›››";
								float: right;
								color: $darkblue;
							}

							h6:first-child::before {
								border: 2px solid #3d3d3f;
								border-radius: 100%;
								width: 15px;
								height: 15px;
								content: " ";
								display: inline-block;
								margin-right: 1rem;
							}

							.position {
								margin-left: 2.125rem;
							}

							a::before {
								display: none;
							}
						}
					}

					ul {
						margin: 0;

						li {
							list-style: none;
							margin: 0;
						}
					}
				}

				.tx-ttaddress {
					.tt_address_list.list {
						.vcardlist {
							grid-template-columns: 100%;
						}

						.vcard {
							a {
								width: 100%;

								span {
									max-width: 100%;
								}
							}

							a.large-10::after {
								display: none;
								content: "";
							}
						}

						.vcard:nth-child(2n),
						.vcard:nth-child(2n+1) {
							grid-column: 1;
							padding: 2.5rem .25rem 1rem;
							border-right: none;
							max-width: 100%;
							width: 100%;
						}
					}
				}

			}

		}
	}
}

/* iPhone 5 & 5S in landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2.0) {
}

/* iPhone 5 & 5S in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2.0) {
}

/* -------------------------------------------- */
/* iPhone 6 Media Queries */
/* iPhone 6 in portrait & landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
	html {
		body {
			[class^="row element"] {
				.row {
					margin: 0 auto;
				}

				.row.element2cols.col50-50 {
					margin: 0;
				}

				.powermail_fieldset {
					margin: 0 -.75rem;

					.layout2,
					.layout3 {
						padding: 0 .75rem;
					}
				}

				.tx-ttaddress {
					.tt_address_list.worker,
					.tt_address_list.workers {
						.worker.vcard {
							width: 100%;
							max-width: 100%;
							flex: unset;

							.addressImage {
								display: block;
								text-align: center;
							}

							.large-8 {
								width: 100%;
								max-width: 100%;
								display: block;

								a {
									padding-left: 0;
								}
							}
						}
					}

					.tt_address_detail {
						.text-center {
							flex: 0 0 100%;
							max-width: 100%;
						}
					}

					.figure {
						width: 10.5rem;
						height: 10.5rem;
						overflow: hidden;
					}

					.rounded {
						border-radius: 50%;
					}

					.square {
					}

					.vcard {
						margin: 0;
						border-bottom: 1px solid $lightgray;

						label {
							font-size: 1.125rem;
							display: inline-block;
							font-family: $body-font-familyBold;
							//min-width: 5rem;
						}

						a {
							font-size: 1.125rem;
							display: inline-block;
							font-family: $body-font-familyBold;
							text-transform: none;
						}

						a::before {
							display: none;
						}
					}

					.contact {
						padding: 0;

						li {
							margin: 0 0 1.725rem;

							h5 {
								margin-bottom: 0;
							}

							.rounded::after {
								display: none;
								content: "";
							}

							h6.noborder {
								border: none;
							}

							a h6.noborder::after {
								content: "›››";
								float: right;
								color: $darkblue;
							}

							h6:first-child::before {
								border: 2px solid #3d3d3f;
								border-radius: 100%;
								width: 15px;
								height: 15px;
								content: " ";
								display: inline-block;
								margin-right: 1rem;
							}

							.position {
								margin-left: 2.125rem;
							}

							a::before {
								display: none;
							}
						}
					}

					ul {
						margin: 0;

						li {
							list-style: none;
							margin: 0;
						}
					}
				}

				.tx-ttaddress {
					.tt_address_list.list {
						.vcardlist {
							grid-template-columns: 100%;
						}

						.vcard {
							a {
								width: 100%;

								span {
									max-width: 100%;
								}
							}

							a.large-10::after {
								display: none;
								content: "";
							}
						}

						.vcard:nth-child(2n),
						.vcard:nth-child(2n+1) {
							grid-column: 1;
							padding: 2.5rem .25rem 1rem;
							border-right: none;
							max-width: 100%;
							width: 100%;
						}
					}
				}

			}

		}
	}
}

/* iPhone 6 in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6 in portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 Plus in portrait & landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
}

/* iPhone 6 Plus in landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
}

/* iPhone 6 Plus in portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* generell 768 x 1024 portrait & landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}

@media only screen and (min-width: 737px) and (max-width: 1024px) and (orientation: portrait) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* -------------------------------------------- */
/* iPad mini 1 & 2 in portrait & landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad mini in landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad mini in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* -------------------------------------------- */
/* iPad mini 3 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad mini in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad mini in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* -------------------------------------------- */
/* iPad in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* iPad in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

/* iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* iPad 1 & 2 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad 1 & 2 in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad 1 & 2 in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* -------------------------------------------- */
/* iPad 3 & 4 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad 3 & 4 in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad 3 & 4 in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* -------------------------------------------- */
/* iPad Pro in portrait & landscape */
/*only screen and (width: 678px) and (height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2)/* and (device-width: 1366px)  /*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (min-height: 981px) and (max-height: 1366px) and (min-width: 981px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-height: 1024px) and (max-height: 1366px) and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad Pro Portrait */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
}

/* iPad Pro Landscape */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (height: 1024px) and (width: 1366px) and (orientation: landscape) {
}

/* iPad Pro Teilscreen */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 981px) and (height: 1024px) {
}

/* später aktivieren */
/* iPad Pro Teilscreen */
/* and (device-width: 1366px)  /*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 678px) and (height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* andere hochauflösende Displays */
@media screen and (-webkit-min-device-pixel-ratio: 3.0) and (min-width: 800px) and (max-width: 1280px) {
}

@media screen and (-webkit-min-device-pixel-ratio: 3.0) and (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
}

/* ----------- Galaxy Tab 10.1 ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
}

/* Portrait */
@media only screen and (max-width: 800px) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (max-width: 1280px) and (orientation: landscape) {
}

/* ----------- Asus Nexus 7 ----------- */
/* Portrait and Landscape */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
}

/* Portrait */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 7" ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 8.9" ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

@media only screen and (min-width: 1850px) {
}

@media only screen and (min-aspect-ratio: 1/1) and (max-aspect-ratio: 16/10) {
}
